.customer-support-chat {
    position: fixed;
    z-index: 99999;
    width: 30%;
    bottom: 1%;
    right: 0;
    margin: 10px;
    &.collapsing {
        position: fixed;
    }

    .chat-content {
        overflow-y: scroll;
        max-height: 75vh;
        min-height: 40vh;
    }

    .other-text {
        padding-left: 1rem;
        margin-left: 1rem;
        padding-right: 0.5rem;
        width: 75%;
        border-radius: 0.25rem;
        border: 1px solid #dee2e6;
        background-color: #c2c7cb;
    }

    .me-text {
        text-align: right;
        padding-left: 0.5rem;
        padding-right: 1rem;
        margin-right: 1rem;
        margin-left: 25%;
        width: 75%;
        border-radius: 0.25rem;
        border: 1px solid #dee2e6;
        background-color: #b4d6f2;
    }

    .sender {
        font-size: 0.7rem;
    }

    .timestamp {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 600px) {
    footer {
        .customer-support-chat {
            position: fixed;
            z-index: 99999;
            width: 90% !important;
            bottom: 1%;
            right: 0;
            margin: 10px;
            &.collapsing {
                position: fixed;
            }
        }
    }
}
