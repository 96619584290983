.baseTransition {
    display: none;
    background-color: black;
    height: 100vh;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
}

#workContainer {
    height: 75%;
    .grid-item {
        position: relative;
        z-index: 1;
        width: 19.9%;
        height: 300px;
        .work-item-link {
            display: inline-block;
            z-index: 9999999;
            width: 100%;
        }
        .grid-item--text {
            opacity: 0;
            background-color: #1d1d1b;
            padding: 20px 25px;
            height: 100%;
            width: 100%;
            transition: 400ms;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: white;
            position: absolute;
            top: 0;
            &:hover {
                opacity: 1;
            }
        }
    }
}

.template__workDetail {
    main {
        #workDetail {
            background-color: black;
            height: calc(100vh - 155px);
            .work_images_wrapp {
                .row {
                    margin-right: 0;
                    margin-left: 0;
                    .remove_padding_div {
                        padding: 0 !important;
                    }
                    .work_images_wrapp--image {
                        width: 100%;
                        height: calc(100vh - 155px);
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                     .work_images_wrapp--image-2 {
                        width: 100%;
                        height: calc(57vh - 155px);
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
            }
            .work_info_wrapp {
                h1,
                p,
                a {
                    color: white !important;
                }
                .workDetail_price_buy {
                    .workDetail_price_buy--buy {
                        float: right;
                        margin-right: 1.5rem;
                    }
                }
            }
            .column_middle_height {
                height: calc(calc(100vh - 155px) / 2) !important;
            }
        }
    }
    footer {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    #workContainer {
        height: 75%;
        .grid-item {
            float: left;
            width: 100% !important;
            height: 300px;
            .work-item-link {
                display: inline-block;
            }
            .grid-item--text {
                opacity: 0;
                background-color: #1d1d1b;
                padding: 20px 25px;
                height: 100%;
                width: 100%;
                transition: 400ms;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                color: white;
                position: absolute;
                top: 0;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
