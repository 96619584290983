#manifest_goals {
    .goals_wrapper {
        margin-bottom: 20px;
        .goals_item {
            h6,
            p {
                font-size: 0.8rem;
                line-height: 1.2rem;
                letter-spacing: 0.096rem;
                word-spacing: 0.128rem;

            }
            h6 {
                border-bottom: 1px solid black;
                display: inline;
                padding-bottom: 3px;
            }
        }
    }
}

.box_wrapper {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    .box_item {
        aspect-ratio: 1;
        width: 100%;
        cursor: pointer;
        background-size: cover;
        background-position: center;
        .box_item--text {
            opacity: 0;
            background-color: #1d1d1b;
            padding: 20px 25px;
            height: 100%;
            width: 100%;
            transition: 400ms;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            color: white;
            &:hover {
                opacity: 1;
            }
            a {
                color: white !important;
            }
        }
    }
}
