footer {
    background-color: #d1cfcf;

    .row {
        .col-12 {
            width: 80%;
            max-width: 1400px;
            margin: 0 auto;

            &.footer_brand_social_media {
                border-bottom: 0.0625rem solid #eaeaea;
                .footer_brand_social_media--item {
                    border-right: 0.0625rem solid #eaeaea;
                    font-size: 20px;
                    &:last-child {
                        border-right: 0;
                    }
                    .logo_footer {
                        width: 200px;
                    }
                    i {
                        font-size: 20px;
                    }
                }
            }

            .footer_icon_text_item {
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .footer_icon {
                        font-size: 30px;
                        margin-right: 20px;
                    }
                    .footer_icon:hover {
                        text-decoration: none;
                    }
                    .footer_text_after_icon {
                        .footer_text_after_icon--title {
                            font-size: 18px;
                        }
                        p {
                            margin-bottom: 0;
                        }
                        .footer_text_after_icon--title:hover {
                            text-decoration: underline;
                        }
                        p:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .footer-list {
                list-style-type: none;
                font-size: 0.8em;
            }
        }
    }
}
