#app.container {
    width: 100%;
    max-width: none;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: 0.144rem;
    word-spacing: 0.193rem;
    .container_content {
        width: 80%;
        max-width: 1400px;
        margin: 0 auto;
    }

    a {
        color: #000;
    }

    .bold_title {
        font-family: Raleway_Bold;
        font-weight: 700;
    }
}

h5 {
    margin-top: 0.5rem !important;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.list-25 {
    max-height: 25% !important;
}

.no-controls {
    pointer-events: none;
}

.static_img {
    position: absolute;
    background: white;
}

.static_img:hover {
    opacity: 0;
}

.customer-support-chat {
    position: fixed;
    z-index: 99999;
    width: 30%;
    bottom: 1%;
    right: 0;
    margin: 10px;
    &.collapsing {
        position: fixed;
    }

    .chat-content {
        overflow-y: scroll;
        max-height: 75vh;
        min-height: 40vh;
    }

    .other-text {
        padding-left: 1rem;
        margin-left: 1rem;
        padding-right: 0.5rem;
        width: 75%;
        border-radius: 0.25rem;
        border: 1px solid #dee2e6;
        background-color: #c2c7cb;
    }

    .me-text {
        text-align: right;
        padding-left: 0.5rem;
        padding-right: 1rem;
        margin-right: 1rem;
        margin-left: 25%;
        width: 75%;
        border-radius: 0.25rem;
        border: 1px solid #dee2e6;
        background-color: #b4d6f2;
    }

    .sender {
        font-size: 0.7rem;
    }

    .timestamp {
        font-size: 0.7rem;
    }
}

.text-manifest{
    font-size:1.2rem ;
    line-height: 1.8rem;
    letter-spacing: 0.144rem;
    word-spacing: 0.193rem;

}

.goal-text{
    font-size:0.8rem ;
    line-height: 1.2rem;
    letter-spacing: 0.096rem;
    word-spacing: 0.128rem;

}

h2 {
    font-size:1.8rem ;
    line-height: 2.7rem !important;
    letter-spacing: 0.216rem;
    word-spacing: 0.288rem;

}

h6{
     font-size:0.9rem;
     line-height: 1.35rem !important;
    letter-spacing: 0.108rem;
    word-spacing: 0.144rem;

}

.button{
    font-size:1.8rem ;
    line-height: 2.7rem !important;
    letter-spacing: 0.216rem;
    word-spacing: 0.288rem;

}
