#app.container {
    header {
        nav {
            ul.navbar-nav {
                align-items: center;
                li {
                    position: relative;
                    &.active {
                        a {
                           &:after {
                            position: absolute;
                            top: 45px;
                            left:0;
                            right:0;
                            margin-left:auto;
                            margin-right:auto;
                            content: '';
                            width: 20px;
                            height: 2px;
                            background-color: #1D1D1B;
                           } 
                        }
                    }
                    a {
                        font-size: 24px;
                        color: rgba(0, 0, 0, 0.5);
                        &:hover,
                        &:focus {
                            color: rgba(200, 200, 200, 0.9);
                        }
                    }
                    &.logo {
                        img {
                            width: 65px;
                        }
                    }
                }
            }
        }
        .header_sticky_elements {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 999999;
            display: inline-block;
            .user_icons {
                display: inline-block;
                margin-right: 10px;
                img {
                    width: 95%;
                }
            }
            .language_selector {
                display: inline-block;
            }
            .header_sticky--item {
                background-color: black;
                width: 30px;
                padding: 2px 5px;
                text-decoration: none;
                color: white;
                display: table-cell;
                &.active {
                    color: white;
                    font-weight: bold;
                }
            }
        }
    }
}